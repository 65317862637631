import { HOC_MDXWrapper } from 'nextra/setup-page'
import { pageMap } from '/vercel/path0/website/.next/static/chunks/nextra-page-map-.mjs'
/*@jsxRuntime automatic*/
/*@jsxImportSource react*/
import {useMDXComponents as _provideComponents} from "nextra/mdx";
const title = "Index";
const frontMatter = {};
export function useTOC(props) {
  return [];
}
function MDXLayout(props) {
  const _components = {
    a: "a",
    blockquote: "blockquote",
    code: "code",
    img: "img",
    li: "li",
    p: "p",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return <><_components.p><_components.a href="https://react-rx.dev/"><_components.img src="https://user-images.githubusercontent.com/81981/194187624-9abd09da-bf03-4886-b512-78c1f22fc2de.png" alt="react-rx-some-smaller" /></_components.a></_components.p>{"\n"}<_components.blockquote>{"\n"}<_components.p>{"Hooks for combining React with RxJS Observables"}</_components.p>{"\n"}</_components.blockquote>{"\n"}<_components.p>{"Features:"}</_components.p>{"\n"}<_components.ul>{"\n"}<_components.li>{"Works well with "}<_components.code>{"Observables"}</_components.code>{" emitting values synchronously. You don’t pay the re-render-on-mount tax."}</_components.li>{"\n"}<_components.li>{"Lightweight. Implemented on top of a small React Hook based core."}</_components.li>{"\n"}<_components.li>{"Full TypeScript support."}</_components.li>{"\n"}</_components.ul></>;
}


export default HOC_MDXWrapper(
  MDXLayout,
  '/',
  {"filePath":"src/pages/index.md","timestamp":1738666465000,pageMap,frontMatter,title},
  typeof RemoteContent === 'undefined' ? useTOC : RemoteContent.useTOC
)